<template>
  <!-- HTML template for the component -->
  <div>
    <div class="row mt-3">
      <div class="col-4">&nbsp;</div>
      <div class="col-8">
        <p style="margin-left: 80px">Ditetapkan di Soreang</p>
        <p  v-if="document.document.penomoran" style="margin-left:80px">Pada Tanggal {{ formatDate(document.document.determination_date) }}</p>
        <p  v-else style="margin-left:80px">Pada Tanggal </p>
        <p style="padding: 20px 0px 0px 0px; text-align: center">
          SEKRETARIS DAERAH KABUPATEN BANDUNG SELAKU PENGGUNA BARANG,
        </p>

        <p
          style="padding: 50px 20px 0px 0px; line-height: 3; text-align: center"
        >
          CAKRA AMIYANA
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // Component's data properties
  data() {
    return {
      document:{}
    };
  },
  // Component's methods
  methods: {
    formatDate(date) {
        return this.$moment(date).format("Do MMMM YYYY");
    },
  },
  mounted() {
      // this.$emit('can-continue', {value: true});
      this.document = this.$store.state.document;
  },
};
</script>

<template>
    <!-- HTML template for the component -->
    <div>
        <p style="text-align:center; margin-top:85px; line-height: 3">KEPUTUSAN SEKERTARIS DAERAH</p>
        <p style="text-align:center; margin-top:0px; ">SELAKU PENGGUNA ANGGARAN </p>
        <p style="text-align:center; margin-top:20px; "> </p>
    </div>
  </template>
  
  <script>
  export default {
    // Component's data properties
    data() {
      return {
        
      };
    },
    // Component's methods
    methods: {
      
    },
  };
  </script>
<template>
    <div class="container">
        <div class="form-group">
            <label for="title">Jumlah Lampiran*</label>
            <input type="number" class="form-control" @input="onChange" v-model="document.document.total_attachment" id="lampiran" aria-describedby="title" placeholder="Ketik Jumlah Lampiran">
        </div>
        <div class="row">
            <div class="col">
                <label for="document_supports">Upload lampiran *</label>
                <div
                    class="card border d-flex justify-content-center text-center"
                    style="height: 150px"
                >
                    <div class="align-self-center mb-3">
                        <img src="@/assets/upload.png" width="100px" />
                    </div>
                    <button
                        class="btn btn-sm btn-primary align-self-center"
                        style="width: 15%"
                        @click="upload"
                        >
                        Upload Disini
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="div">
                    <input
                        hidden
                        type="file"
                        class="form-control"
                        ref="uploadField"
                        id="upload_file"
                        @change="selectFile"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1" v-for="(item) in document_attachments" :key="item.description">
                <CCardLink :href="item.file" target="_blank" class="">
                    <img src="@/assets/attachment.png" width="100%" />
                </CCardLink>
                <label style="text-align: center;">{{ item.tittle }}</label>
                <!-- <button
                    class="btn btn-danger btn-sm btn-block mt-2"
                    @click="deleteFile(index)"
                >
                    Hapus
                </button> -->
                <br />
                <a :href="item.description" target="_blank" 
                    class="btn btn-sm btn-secondary align-self-center"
                    >
                    Download
                </a>
                <br />
            </div>
        </div>
        
        <small id="document_type" class="form-text text-muted mt-4"><strong>*Note: Lampiran boleh diisi bila diperlukan! Tekan tombol Tambah Lapiran (Input) untuk membuat lampiran dengan inputan, Tekan tombol Tambah Lampiran (Upload File) untuk membuat lampiran dari file upload. file upload harus berupa format word atau excel</strong></small>
        <!-- <button @click="add(false)" class="btn btn-sm btn-success mt-3 mr-2"><CIcon name="cilMedicalCross" /> Tambah Lampiran (Input)</button>
        <button @click="add(true)" class="btn btn-sm btn-success mt-3"><CIcon name="cilMedicalCross" /> Tambah Lampiran (Upload File)</button> -->
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
    import { VueEditor } from "vue2-editor";
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import { uploadFile } from "@/utils/fileUpload";
 
    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin],
        data() {
            return {
                document: {},
                document_attachments: [
                    // {
                    //     tittle: '',
                    //     description: '',
                    //     is_file: false,
                    //     margin_top: 0,
                    //     margin_left: 0,
                    //     margin_bottom: 0,
                    //     margin_right: 0,
                    // }
                ],
                customToolbar: [
                    ["bold", "italic", "underline"],
                ],
                editor: ClassicEditor,
                editorData: '',
                editorConfig: {
                    // The configuration of the editor.
                    resize_maxWidth: 800
                }
            }
        },
        components: {
            VueEditor
        },
        validations: {
            document_attachments: {
                $each: {
                    tittle: { required },
                    description: { required },
                }
            }
        },
        watch: {
            $v: {
                handler: function (val) {
                    if(!val.$invalid) {
                        this.$store.commit('document/setDocumentAttachments', this.document_attachments);
                        this.$emit('can-continue', {value: true});
                    } else {
                        this.$emit('can-continue', {value: false});
                    }
                },
                deep: true
            },
            currentStep(val) {
                if(!this.$v.invalid) {
                    this.$emit('can-continue', {value: true});
                } else {
                    this.$emit('can-continue', {value: false});
                }
            }
        },
        methods: {
            onChange(event) {
                console.log(event.target.value, this.document);
                this.$store.commit('document/setDocument', this.document.document);
            },
            upload() {
                // this.$refs.uploadField.click();
                // document.getElementById('upload_file').click();
                document.getElementById('upload_file').click();
            },
            selectFile(event, index) {

                // this.file = event.target.files[0];
                // var loading = this.$loading.show();
                // uploadFile(this.file)
                //     .then((resp) => {
                //         this.document_attachments[index].description = resp;
                //         loading.hide();
                //         this.$swal.fire({
                //             icon: 'success',
                //             text: 'File berhasil diupload !!',
                //         });
                //     })
                //     .catch((e) => {
                //         loading.hide();
                //         this.$swal.fire({
                //             icon: 'warning',
                //             text: 'Terjadi kesalahan, ' + e,
                //         })
                //     });

                this.file = event.target.files[0];
                var loading = this.$loading.show();
                uploadFile(this.file)
                    .then((resp) => {
                        this.document_attachments.push({
                            tittle: 'Versi ' + (this.document_attachments.length + 1),
                            description: resp,
                            is_file: 1,
                            margin_top: 0,
                            margin_left: 0,
                            margin_bottom: 0,
                            margin_right: 0,

                        });
                        loading.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: 'File berhasil diupload !!',
                        });
                    })
                    .catch((e) => {
                        loading.hide();
                        this.$swal.fire({
                            icon: 'warning',
                            text: 'Terjadi kesalahan, ' + e,
                        })
                    });
            },
            add(isFile) {
                this.document_attachments.push({
                    description: '',
                    is_file: isFile,
                    margin_top: 0,
                    margin_left: 0,
                    margin_bottom: 0,
                    margin_right: 0,
                })
            },
            deleteDetail(index) {
                this.document_attachments.splice(index, 1);
            }
        },
        mounted() {
            this.document = this.$store.state.document;
            let _document_attachments = this.$store.state.document.document_attachments;
            if (_document_attachments.length > 0) {
                this.document_attachments = _document_attachments;
            }
            if(!this.$v.$invalid) {
                this.$emit('can-continue', {value: true});
            } else {
                this.$emit('can-continue', {value: false});
            }
        }
    }
</script>

<style>
    .ck-editor .ck-editor__main .ck-content {
        min-height: 200px !important;
        max-width: 1000px !important;
        width: 1000px !important;
    }
</style>